var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "", id: "searchBar" } },
    [
      _vm.showUserBox
        ? _c(
            "div",
            { staticClass: "userBox" },
            [
              _c("Navigation"),
              _vm.mixinGetIntegrationInfo().integration ? _c("User") : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("Breadcrumbs", { attrs: { activeStep: _vm.getActiveStep() } }),
      _c("SearchForm", {
        attrs: {
          boxsetNights: _vm.dates.nights,
          datePickerSelector: _vm.datePickerSelector,
          loading: _vm.loading,
          lastSearch: _vm.lastSearch,
          offset: _vm.offset,
        },
        on: {
          "submit-search": _vm.searchSubmitted,
          "filter-changed": _vm.filterChanged,
        },
      }),
      _vm.hasResults
        ? _c("SearchResume", {
            attrs: {
              band: _vm.band,
              focus: _vm.focus,
              geo: _vm.geo.label,
              checkin: _vm.dates.checkin,
              nights: _vm.dates.nights,
              occupancy: _vm.occupancy,
              loading: _vm.loading,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }