<template>
    <b-container fluid id="searchBar">
        <div class="userBox" v-if="showUserBox">
            <Navigation />
            <User v-if="mixinGetIntegrationInfo().integration"/>
        </div>
        <Breadcrumbs :activeStep="getActiveStep()"/>
        <SearchForm 
            v-on:submit-search="searchSubmitted"
            v-on:filter-changed="filterChanged"
            :boxsetNights="dates.nights"
            :datePickerSelector="datePickerSelector"
            :loading="loading"
            :lastSearch="lastSearch"
            :offset="offset"
        />
        <SearchResume
            :band="band"
            :focus="focus"
            :geo="geo.label"
            :checkin="dates.checkin"
            :nights="dates.nights"
            :occupancy="occupancy"
            :loading="loading"
            v-if="hasResults"
        />
    </b-container>
</template>

<script>
    import SearchForm from '../blocks/search-form/search-form'
    import Navigation from '../blocks/topbar/navigation'
    import User from '../blocks/topbar/user'
    import Breadcrumbs from '../blocks/topbar/breadcrumbs'
    import SearchResume from '../blocks/search-form/search-resume'

    export default {
        name: 'searchBar',
        components: {
            Navigation,
            User,
            Breadcrumbs,
            SearchResume,
            SearchForm,
        },
        props: {
            loading: Boolean,
            offset: Number,
            band: String,
            focus: String,
            nights: Number,
            geo: Object,
            dates: Object,
            occupancy: Object,
            hasResults: Boolean,
            lastSearch: String,
            showUserBox:
            {
                type: Boolean,
                default: true,
            },
            datePickerSelector:
            {
                type: String,
                default: 'datepicker-trigger',
            }            
        },
        data() {
            return {
                //loading: false
            }
        },
        mounted()
        {},
        methods:{
            getActiveStep()
            {
                let route = this.$route.name
                if(this.mixinGetIntegrationInfo().mode == 'boxset')
                {
                    if(route == 'before-checkout') return 2
                    return 1
                }
                if(this.mixinGetIntegrationInfo().mode == 'booking')
                {
                    return 3
                }
            },
            searchSubmitted()
            {
                this.$emit('submit-search')
            },
            filterChanged()
            {
                this.$emit('filter-changed')
            }
        },     
    }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';

    #searchBar
    {
        display:flex;
        flex-direction:column;
        background:$light-gray;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;

        .user
        {
            @include media-breakpoint-up(lg)
            {
                .logo > div
                {
                    height:46px;
                }
            }
        }
        .userBox
        {
            order:1;
            display:none;
        }
        .breadcrumbs
        {
            order:3;
        }
        #search-form
        {
            order:2;
        }
        .searchResume
        {
            order:4;
        }

        #search-form-filters
        {
            button
            {
                border:0;
                background:$white;
            }
        }
        
        @include media-breakpoint-up(lg)
        {
            //padding:0.75rem $grid-gutter-width/2 0.75rem $grid-gutter-width;
            padding-top:0.75rem;
            padding-bottom:0.75rem;
            #search-form
            {
                padding-right:$grid-gutter-width/2;
            }
            box-shadow: $box-shadow;
            .userBox
            {
                order:1;
                display:flex;
                justify-content: flex-end;
                align-items:center;
            }
            .breadcrumbs
            {
                order:2;
            }
            #search-form
            {
                order:3;
            }
            .searchResume
            {
                order:4;
            }
        }

        @include media-breakpoint-only(lg)
        {
            $input-pad: 8px;
            #search-form
            {
                padding-right:0;
                
                #search-form-autocomplete
                {
                    flex:40%;
                    margin-bottom:1rem;
                    input
                    {
                        padding-top:$input-pad;
                        padding-bottom:$input-pad;
                        height:auto;
                    }
                }
                #search-form-datepicker
                {
                    flex:35%;
                    input
                    {
                        padding-top:$input-pad;
                        padding-bottom:$input-pad;
                        height:auto;
                    }
                }
                #search-form-nights
                {
                    flex:25%;
                    padding-right:0;
                    select
                    {
                        padding-top:$input-pad;
                        padding-bottom:$input-pad;
                        height:auto;
                    }
                }
                #search-form-occupancy
                {
                    flex:40%;
                    button
                    {
                        padding-top:$input-pad;
                        padding-bottom:$input-pad;
                        height:auto;
                    }
                }
                #search-form-buttons
                {
                    flex:25%;
                    order:6;
                    padding-right:0;
                    button
                    {
                        padding-top:$input-pad;
                        padding-bottom:$input-pad;
                        height:auto;
                    }
                }
                #search-form-filters
                {
                    flex:35%;
                    order:5;
                    padding-right:$grid-gutter-width/3;
                    button
                    {
                        padding-top:$input-pad;
                        padding-bottom:$input-pad;
                        height:auto;
                    }
                }
            }
        }
        @include media-breakpoint-only(xl)
        {
            $input-pad: 8px;
            #search-form
            {
                //padding-right:0;
                margin-top:$grid-gutter-width/8;
                margin-bottom:$grid-gutter-width/8;
                #search-form-autocomplete,
                #search-form-datepicker,
                #search-form-nights,
                #search-form-occupancy,
                #search-form-buttons,
                #search-form-filters
                {
                    //padding-right:$grid-gutter-width/3;
                }
                
                #search-form-autocomplete
                {
                    flex:40%;
                    margin-bottom:$grid-gutter-width/4;
                    input
                    {
                        padding-top:$input-pad;
                        padding-bottom:$input-pad;
                        height:auto;
                    }
                }
                #search-form-datepicker
                {
                    flex:35%;
                    input
                    {
                        padding-top:$input-pad;
                        padding-bottom:$input-pad;
                        height:auto;
                    }
                }
                #search-form-nights
                {
                    flex:25%;
                    padding-right:0;
                    select
                    {
                        padding-top:$input-pad;
                        padding-bottom:$input-pad;
                        height:auto;
                    }
                }
                #search-form-occupancy
                {
                    flex:40%;
                    button
                    {
                        padding-top:$input-pad;
                        padding-bottom:$input-pad;
                        height:auto;
                    }
                }
                #search-form-buttons
                {
                    flex:25%;
                    order:6;
                    padding-right:0;
                    button
                    {
                        padding-top:$input-pad;
                        padding-bottom:$input-pad;
                        height:auto;
                    }
                }
                #search-form-filters
                {
                    flex:35%;
                    order:5;
                    padding-right:$grid-gutter-width/2;
                    button
                    {
                        padding-top:$input-pad;
                        padding-bottom:$input-pad;
                        height:auto;
                    }
                }
            }
        }
    }    
</style>